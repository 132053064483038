<script setup>
import SpecialOrdersLayout from '@/layouts/SpecialOrdersLayout.vue';
import { computed, onMounted, ref } from 'vue';
import useSpecialOrders from '@/hooks/useSpecialOrders';
import { useRoute } from 'vue-router';
import { setPageTitle } from '@/utils/pageTitle';

const { fetchSpecialOrder, specialOrder, fetchSpecialOrderItems, fetchSpecialHistory } = useSpecialOrders();

const route = useRoute();
const orderItems = ref([]);
const orderHistory = ref([]);

onMounted(async () => {
  await fetchSpecialOrder(route.params.id);
  setPageTitle('SLO')
  orderItems.value = await fetchSpecialOrderItems(route.params.id);
  orderHistory.value = await fetchSpecialHistory(route.params.id);
})

const storeAddress = computed(() => {
  return `${specialOrder.value.store.id} - ${specialOrder.value.store.address || 'MISSING'} ${specialOrder.value.store.city || 'MISSING'}, ${specialOrder.value.store.state} ${specialOrder.value.store.zip}`
});

</script>

<template>
  <SpecialOrdersLayout v-if="specialOrder" :special-order="specialOrder">
    <v-row class="pa-4">
      <v-col cols="8">
        <v-row>
          <v-col>
            <v-text-field label="Week Ending" variant="underlined" readonly v-model="specialOrder.week" />
            <v-text-field label="PO Number" variant="underlined" readonly v-model="specialOrder.po_number" />
            <v-text-field label="Client" variant="underlined" readonly v-model="specialOrder.client.name" />
          </v-col>

          <v-col>
            <v-text-field label="Order Number" variant="underlined" readonly v-model="specialOrder.client_order_number" />
            <v-text-field label="Customer Number" variant="underlined" readonly v-model="specialOrder.client_customer_number" />
            <v-text-field label="Customer" variant="underlined" readonly v-model="specialOrder.client_customer_name" />
          </v-col>

          <v-col>
            <v-text-field label="Status" variant="underlined" readonly v-model="specialOrder.order_status.name" />
            <v-text-field label="Store" variant="underlined" readonly v-model="storeAddress" />
          </v-col>
        </v-row>

        <v-divider></v-divider>

        <v-card class="mt-4" density="compact">
          <v-table>
            <thead>
              <tr>
                <th>SKU</th>
                <th>DESCRIPTION</th>
                <th>CS QTY</th>
                <th>BTL QTY</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in orderItems" :key="item.id">
                <td>{{ item.sku }}</td>
                <td>{{ item.description }}</td>
                <td>{{ item.case_qty }}</td>
                <td>{{ item.bottle_qty }}</td>
              </tr>
              <tr v-if="orderItems.length == 0">
                <td colspan="4">
                  <div class="text-center w-100">No items.</div>
                </td>
              </tr>
            </tbody>
          </v-table>
        </v-card>
      </v-col>

      <v-col cols="4">
        <div class="text-center mb-4">Order History</div>
        <v-timeline density="compact" size="small" align="start">
          <v-timeline-item
            v-for="item in orderHistory" :key="item.id"
            class="mb-1"
            color="grey"
            size="small"
          >
            <v-row justify="space-between">
              <v-col cols="12">
                {{ item.body }}<br />
                <small>{{ item.user.name }} @ {{ new Date(item.created_at).toLocaleDateString() }}</small>
              </v-col>
            </v-row>
          </v-timeline-item>
        </v-timeline>
      </v-col>
    </v-row>
  </SpecialOrdersLayout>
</template>

<style lang="scss" scoped>

</style>